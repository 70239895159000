import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { format as formatDate, parseISO as parseDate } from "date-fns";

const CATALOG_URL =
  "https://prl-eww-eu-west-1.s3-eu-west-1.amazonaws.com/eww.json";

const COLUMNS_LARGE = [
  {
    name: "Date",
    selector: (row) => row.published_at,
    format: formatPublishedAtDateLarge,
    sortable: true,
    width: "11rem",
  },
  {
    name: "Title",
    selector: getFilmTitle,
    format: formatVideoTitleLarge,
    sortable: true,
  },
];

const COLUMNS_SMALL = [
  {
    name: "Date",
    selector: (row) => row.published_at,
    format: formatPublishedAtDateSmall,
    sortable: true,
    width: "7rem",
  },
  {
    name: "Title",
    selector: getFilmTitle,
    format: formatVideoTitleSmall,
    sortable: true,
  },
];

function youtubeUrl(videoId) {
  return `https://www.youtube.com/watch?v=${videoId}`;
}

function formatPublishedAtDateLarge(row) {
  const timestamp = parseDate(row.published_at);
  return formatDate(timestamp, "MMMM do, yyyy");
}

function formatPublishedAtDateSmall(row) {
  return row.published_at.substr(0, 10);
}

function getFilmTitle(row) {
  if (row.film !== null) {
    if (row.film.substr(0, 3).toLowerCase() === "the") {
      return row.film.substr(4);
    }

    return row.film;
  }

  return row.title;
}

function UnstyledLink(props) {
  return (
    <a {...props} style={{ color: "inherit", textDecoration: "inherit" }}>
      {props.children}
    </a>
  );
}

function formatVideoTitleLarge(row) {
  const url = youtubeUrl(row.id);

  if (row.film === null) {
    return <UnstyledLink href={url}>{row.title}</UnstyledLink>;
  }

  const eww = <span className="text-muted">Everything Wrong With</span>;
  const film = <span className="mx-1 fs-6 fw-bold">{row.film}</span>;

  if (row.minutes_or_less !== null) {
    const minutes_or_less = (
      <span className="text-muted">{row.minutes_or_less}</span>
    );
    return (
      <UnstyledLink href={url}>
        {eww} {film} In {minutes_or_less}
      </UnstyledLink>
    );
  } else {
    return (
      <UnstyledLink href={url}>
        {eww} {film}
      </UnstyledLink>
    );
  }
}

function formatVideoTitleSmall(row) {
  const url = youtubeUrl(row.id);

  if (row.film !== null) {
    return (
      <UnstyledLink href={url}>
        <strong>{row.film}</strong>
      </UnstyledLink>
    );
  } else {
    return <UnstyledLink href={url}>{row.title}</UnstyledLink>;
  }
}

function onClick(id) {
  const url = youtubeUrl(id);
  window.location.href = url;
}

function EwwTable() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(CATALOG_URL);

      if (!response.ok) {
        console.log("error", response.status, response.statusText);
        return;
      }

      try {
        const data = await response.json();
        setRows(data.videos);
      } catch (e) {
        console.log("error", e);
      }
    }

    fetchData();
    return () => {};
  }, []);

  return (
    <>
      <div className="d-none d-lg-block">
        <DataTable
          columns={COLUMNS_LARGE}
          data={rows}
          onRowClicked={(row) => onClick(row.id)}
          highlightOnHover={true}
          pointerOnHover={true}
          dense
        />
      </div>
      <div className="d-lg-none">
        <DataTable
          columns={COLUMNS_SMALL}
          data={rows}
          onRowClicked={(row) => onClick(row.id)}
          highlightOnHover={true}
          pointerOnHover={true}
          dense
        />
      </div>
    </>
  );
}

export default EwwTable;
