import React from "react";
import Container from "react-bootstrap/Container";
import EwwTable from "./EwwTable";

const App = () => (
  <Container className="p-3">
    <h1 className="header">Everything Wrong With...</h1>
    <EwwTable />
  </Container>
);

export default App;
